import { useEffect, useMemo } from "react";
import { ErrorView } from "@shared/ErrorView";
import { useSegment } from "@shared/Segment";
import { useCustomRouter } from "@shared/CustomRouter";

/**
 * Error Cause Type to identify expected errors.
 */
export enum ERROR_CAUSE_TYPE {
  /**
   * A company's externalId provided by user input does not exists or is
   * invalid in the given context.
   */
  INVALID_COMPANY = "INVALID_COMPANY",
  /**
   * Forbidden response from server side.
   * - 403 HTTP Status Code.
   * - message: "Forbidden" Graphql Error Message.
   */
  ACCESS_DENIED = "ACCESS_DENIED",
}

type Cause<T extends ERROR_CAUSE_TYPE> = {
  readonly data: DataMap[T];
  readonly type: T;
};

type DataMap = {
  [ERROR_CAUSE_TYPE.INVALID_COMPANY]: {
    companyExternalId: string;
  };
  [ERROR_CAUSE_TYPE.ACCESS_DENIED]: Record<string, string>;
};

type ErrorSceneProps = {
  tryRefresh?: () => void;
};
/**
 *
 * @param props
 * @returns
 */
export function ErrorScene({ tryRefresh }: ErrorSceneProps) {
  const { pathname, query } = useCustomRouter();
  const { page } = useSegment();

  useEffect(() => {
    void page("Generic Error", { pathname });
  }, [page, pathname]);

  const message = useMemo(() => {
    const cause = JSON.parse(
      atob(query.get("cause") ?? "") || "{}"
    ) as Cause<ERROR_CAUSE_TYPE>;

    console.error("Error cause", cause);

    switch (cause?.type) {
      case ERROR_CAUSE_TYPE.INVALID_COMPANY:
        return `Unable to authenticate user "${cause.data?.companyExternalId}" is not a valid company.`;
      case ERROR_CAUSE_TYPE.ACCESS_DENIED:
        return `Access Denied.`;
      default:
        return "Unable to authenticate user. An unknown error has occured.";
    }
  }, [query]);

  console.error("Error scene", message);

  return <ErrorView message={message} tryRefresh={tryRefresh} />;
}
