"use client";

import { ErrorScene } from "@scenes/ErrorScene";
import { DatadogProvider } from "@shared/DatadogProvider";
import { IntlProvider } from "@shared/IntlProvider";

// eslint-disable-next-line import/no-default-export
export default function ErrorPage() {
  // TODO: Evaluate to start using the reset method provided by Next
  // return (
  //   <div>
  //     <h2>Something went wrong!</h2>
  //     <button
  //       onClick={
  //         // Attempt to recover by trying to re-render the segment
  //         () => reset()
  //       }
  //     >
  //       Try again
  //     </button>
  //   </div>
  // )

  // We need these providers here because the Error view relies on them and
  // the layout at this level doesn't have them. These providers rely on
  // the AuthenticationProvider to get the logged user but it doesn't matter
  // if it's not present, they will default their behaviors to
  // a non logged user and that's fine.
  return (
    <DatadogProvider>
      <IntlProvider>
        <ErrorScene />
      </IntlProvider>
    </DatadogProvider>
  );
}
